import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PageNavigationMobile from './PageNavigationMobile';
import PageNavigationDesktop from './PageNavigationDesktop';
import {
  useResizeListener,
  translateRoute,
  renderRoutesRecursively,
} from '../utils';
import Icon from './Icon';
import Translate from './Translate';

import { navigate } from 'gatsby';
import SVG from 'react-inlinesvg';
import fallbackLogoImmocare from '../assets/logo-immocare.png';
// import logoImmocare from '../assets/logo.svg';
import fallbackLogoHP from '../assets/logo.png';
import logoHP from '../assets/logo.svg';

const IMMOCARE_PAGES = ['7DJJdKwUj6YKtf30ebNZ9T', '1bRdV76VjREYRH29NKjVFP'];

const PageNavigation = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  const setMobile = () => {
    setIsMobile(
      window.innerWidth <= 768 ||
        (window.innerWidth < window.innerHeight && window.innerWidth <= 1024)
    );
  };
  useResizeListener(setMobile);

  const {
    contentful_id,
    pages,
    resources,
    config: {
      localization: { locale, locales },
      path,
    },
  } = props;

  let logos = { svg: logoHP, fallback: fallbackLogoHP };
  if (IMMOCARE_PAGES.includes(contentful_id)) {
    logos = { fallback: fallbackLogoImmocare };
  }

  const renderLogo = (styles) => {
    const fallbackImage = (
      <img className={styles.logo} src={logos.fallback} alt="Heid + Partner" />
    );
    if (logos.svg) {
      return (
        <SVG
          className={styles.logo}
          src={logos.svg}
          uniquifyIDs={false}
          onClick={() => navigate(`/${locale.code}`)}
        >
          {fallbackImage}
        </SVG>
      );
    }
    return fallbackImage;
  };

  const renderContact = (styles) => (
    <div className={styles.contact}>
      <Translate resources={resources} translate="navigation.contact" />
      <a href="tel:+49358176430">
        <Icon icon="phone" />
        +49 3581 7643 - 0
      </a>
      <a href="mailto:info@heidpartner.de">
        <Icon icon="email" />
        info@heidpartner.de
      </a>
      <Icon
        onClick={() => translateRoute(path, locale, locales)}
        className={styles.fab}
        icon="globe"
        role="button"
        tooltip={
          <Translate
            resources={resources}
            translate="navigation.button.translate"
          />
        }
      />
    </div>
  );

  const renderRoutes = (styles) => (
    <div className={styles.routes}>{renderRoutesRecursively(pages)}</div>
  );

  if (isMobile) {
    return (
      <PageNavigationMobile
        renderLogo={renderLogo}
        renderContact={renderContact}
        renderRoutes={renderRoutes}
      />
    );
  }

  return (
    <PageNavigationDesktop
      renderLogo={renderLogo}
      renderContact={renderContact}
      renderRoutes={renderRoutes}
      {...props}
    />
  );
};

const localeShape = PropTypes.shape({
  code: PropTypes.string.isRequired,
  localizedPaths: PropTypes.object.isRequired,
});

PageNavigation.propTypes = {
  contentful_id: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  resources: PropTypes.arrayOf(PropTypes.object).isRequired,
  config: PropTypes.shape({
    localization: PropTypes.shape({
      locale: localeShape.isRequired,
      locales: PropTypes.arrayOf(localeShape).isRequired,
    }).isRequired,
  }).isRequired,
};

PageNavigation.defaultProps = {};

export default PageNavigation;
