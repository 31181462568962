import React from 'react';
import GoogleMap from './GoogleMap';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import markerIcon from '../assets/logo_marker_green.png';

const PageMap = (props) => {
  const location = documentToPlainTextString(props.content.json);
  const [lat, lng] = location?.replace('@', '').split(',');
  return <GoogleMap lat={+lat} lng={+lng} markerIcon={markerIcon} />;
};

export default PageMap;
