import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React from 'react';

import logo from './assets/logo.png';

// A4 dimensions: 595 pt x 842 pt

const colorPrimary = '#0f4b55';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: '24pt 36pt 36pt 36pt',
    position: 'relative',
    fontSize: 12,
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 12,
    textAlign: 'center',
  },
  logo: {
    width: '40%',
    paddingBottom: 12,
  },
  heading: {
    fontFamily: 'Raleway',
    paddingBottom: 6,
    fontSize: 16,
    color: colorPrimary,
  },
  subHeading: {
    fontFamily: 'Raleway',
    paddingBottom: 6,
    fontSize: 14,
    color: colorPrimary,
  },
  pill: {
    fontFamily: 'Roboto',
    backgroundColor: '#ee6e73',
    padding: 4,
    borderRadius: 100,
    fontSize: 10,
  },
  paragraph: {
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  tables: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  table: {
    position: 'relative',
    width: '50%',
    padding: '6pt',
  },
  tableRow: {
    width: '100%',
    marginBottom: 5,
    paddingBottom: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableRowColumn: {
    width: '50%',
  },
  section: {
    width: '100%',
    position: 'relative',
    padding: '6pt 0',
  },
  text: {
    padding: '6pt 0',
  },
  headerImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
  },
  headerImage: {
    height: '36pt',
    width: '36pt',
    margin: '0 6pt',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  contactWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  contact: {
    width: '33%',
    padding: '0 6pt',
    color: '#000',
    textDecoration: 'none',
  },
  imageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
  },
  image: {
    padding: '0 6pt 6pt 6pt',
  },
  pageNumberWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 36,
    bottom: 16,
    width: '100%',
    color: '#000',
    textDecoration: 'none',
  },
  pageNumber: {
    fontFamily: 'Roboto',
    fontSize: 10,
  },
});

const MyDocument = ({ data, domain, translate, parseIfNecessary }) => {
  const { title, isSold, specifications, texts, attachments } = data;

  Font.register({
    family: 'Raleway',
    src: '//fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ISottDfA.ttf',
  });
  Font.register({
    family: 'Roboto',
    src: '//fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf',
  });

  const company = {
    name: translate('company.name'),
    email: translate('company.email'),
    phone: translate('company.phone'),
    address: translate('company.address'),
  };

  const emailLink = (
    <Link src={`mailto:${company.email.replace(/ \(\)/g, '')}`}>
      {company.email}
    </Link>
  );

  const phoneLink = (
    <Link src={`tel:${company.phone.replace(/ \(\)/g, '')}`}>
      {company.phone}
    </Link>
  );

  return (
    <Document>
      <Page size="A4" wrap style={styles.page}>
        <View style={styles.header}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.heading}>{title}</Text>
          {isSold && <Text style={styles.pill}>{isSold}</Text>}
        </View>
        {!!attachments?.length && (
          <View style={styles.headerImageWrapper}>
            {attachments.slice(0, 7).map(({ uri }) => (
              <Image key={uri} src={uri} style={styles.headerImage} />
            ))}
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.heading}>{specifications.subHeading}</Text>
          <View style={styles.tables}>
            {specifications.sets.map((set, i) => {
              return (
                <View key={`set-${i}`} style={styles.table}>
                  {set.map(({ key, value }) => {
                    return (
                      <View key={key} style={styles.tableRow} wrap={false}>
                        <Text
                          style={{
                            ...styles.paragraph,
                            ...styles.tableRowColumn,
                            color: colorPrimary,
                          }}
                        >
                          {key}
                        </Text>
                        <Text
                          style={{
                            ...styles.paragraph,
                            ...styles.tableRowColumn,
                            paddingLeft: 6,
                          }}
                        >
                          {parseIfNecessary(value)}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.section}>
          {texts.map(({ key, value }) => {
            const parsed = parseIfNecessary(value);
            if (!parsed) return null;
            return (
              <View key={key} style={styles.text} wrap={false}>
                <Text style={styles.subHeading}>{key}</Text>
                <Text style={{ ...styles.paragraph, padding: '0 6pt' }}>
                  {parsed}
                </Text>
              </View>
            );
          })}
        </View>
        <View style={styles.section} wrap={false}>
          <Text style={styles.subHeading}>{company.name}</Text>
          <View style={styles.contactWrapper}>
            <Text style={{ ...styles.paragraph, ...styles.contact }}>
              <Link src={domain}>{company.address}</Link>
            </Text>
            <Text style={{ ...styles.paragraph, ...styles.contact }}>
              {emailLink}
            </Text>
            <Text style={{ ...styles.paragraph, ...styles.contact }}>
              {phoneLink}
            </Text>
          </View>
        </View>
        {!!attachments?.length && (
          <View style={styles.section} break>
            <Text style={styles.subHeading}>Bilder</Text>
            <View style={styles.imageWrapper}>
              {attachments.map(({ uri, headline }) => (
                <View key={uri} style={styles.image}>
                  <Image src={uri} style={{ height: 190 }} />
                  <Text
                    style={{
                      ...styles.paragraph,
                      paddingTop: 2,
                      fontSize: 8,
                      color: '#4a4a4a',
                    }}
                  >
                    {headline}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}
        <View style={styles.pageNumberWrapper} fixed>
          <Text style={{ ...styles.paragraph, ...styles.pageNumber }}>
            <Link src={domain}>{company.name}</Link>
            {phoneLink} | {emailLink}
          </Text>
          <Text
            fixed
            style={{ ...styles.paragraph, ...styles.pageNumber }}
            render={({ pageNumber, totalPages }) => {
              return `${translate('pdf.page')} ${pageNumber}/${totalPages}`;
            }}
          />
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
