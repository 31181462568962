import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import PropTypes from 'prop-types';
import React from 'react';

import { Link, navigate } from 'gatsby';
import { initLocalized, mergeClassNames } from '../utils';
import Button from './Button';
import Number from './Number';
import * as shapes from './proptypes';

const renderHeading = (node, children) => {
  const Tag = `h${node.nodeType.slice(-1)}`;
  return (
    <Tag>
      {children.map((child, i) =>
        typeof child === 'string' ? (
          <span key={i} dangerouslySetInnerHTML={{ __html: child }} />
        ) : (
          child
        )
      )}
    </Tag>
  );
};

const initOptions = ({ localization: { locale } }) => {
  const resolve = initLocalized(locale);
  return {
    renderMark: {
      [MARKS.CODE]: (content) => <Number content={content} />,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: renderHeading,
      [BLOCKS.HEADING_2]: renderHeading,
      [BLOCKS.HEADING_3]: renderHeading,
      [BLOCKS.HEADING_4]: renderHeading,
      [BLOCKS.HEADING_5]: renderHeading,
      [BLOCKS.HEADING_6]: renderHeading,
      [INLINES.HYPERLINK]: (node, children) => {
        if (node?.data?.uri?.[0] === '/') {
          return <Link to={node.data.uri}>{children}</Link>;
        }
        return (
          <a href={node.data.uri} rel="noopener noreferrer" target="__blank">
            {children}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const { sys, fields } = node?.data?.target;
        if (sys?.contentType?.sys.id !== 'callToAction') return null;
        const label = resolve(fields?.label);
        const url = resolve(fields?.url);
        return <Button onClick={() => navigate(url)}>{label}</Button>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        if (!locale) return;
        const { description, file, title } = node?.data?.target?.fields;

        const { contentType, url } = resolve(file);
        if (url && contentType.includes('image')) {
          const resolvedTitle = resolve(title);
          const resolvedDescription = resolve(description);
          return (
            <img src={url} alt={`${resolvedTitle} - ${resolvedDescription}`} />
          );
        }
      },
    },
  };
};

const RichText = ({ className, json, config }) => {
  if (!json) return null;
  const options = initOptions(config);
  return (
    <article className={mergeClassNames(className)}>
      {documentToReactComponents(json, options)}
    </article>
  );
};

RichText.propTypes = {
  className: PropTypes.string,
  json: shapes.richText,
  config: shapes.config.isRequired,
};

RichText.defaultProps = {
  config: {
    localization: {
      locales: [],
    },
    path: '',
    env: '',
    domain: '',
  },
};

export default RichText;
